<template>
  <div>
    <Bar :isTop="true" />
    <Nav/>
    <slot />
    <Nuxt/>
    <Footer />
    <Bar :isTop="false"/>
  </div>
</template>
<script>
import Nav from '~/components/main/Nav.vue';
import Bar from '~/components/main/Bar.vue';
import Footer from '~/components/main/Footer.vue';
export default

{
  components: {
    Nav,
    Bar,
    Footer
  }
};

</script>
<style scoped>

</style>